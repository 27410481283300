import { Layout } from 'antd'
import { StyledGlobalLayout } from 'components/GlobalStyles'
import PageFooter from 'components/PageFooter'
import PageHeader from 'components/PageHeader'
import PropTypes from 'prop-types'

const { Content } = Layout

export default function Index ({ children, backgroundImage = '/images/bg/standard-bg.png', minHeight = '100vh' }) {
  return (
    <StyledGlobalLayout bg={backgroundImage}>
      <PageHeader />
      <Content style={{ minHeight: minHeight }}>{children({})}</Content>
      <PageFooter />
    </StyledGlobalLayout>
  )
}

Index.propTypes = {
  children: PropTypes.any,
  backgroundImage: PropTypes.string,
  minHeight: PropTypes.string
}
