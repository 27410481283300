import { Button, Result } from 'antd'
import StandardLayout from 'layout/StandardLayout'
import Link from 'next/link'

export default function Home () {
  return (
    <StandardLayout>
      {() => (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, page not found."
            extra={(
              <center>
                <Link href='/'>
                  <Button type="primary">Back Home</Button>
                </Link>
              </center>
            )}
          />
      )}
    </StandardLayout>
  )
}
